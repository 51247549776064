import React, { useState } from 'react'
import "./Timeline_Slider.css"

const Timeline_Slider = ({timeline_content}) => {

  

  const [currentEventIndex, setCurrentEventIndex] = useState([0,0,0]);

  
  const totalEvent= timeline_content.length;


  const eventWidth = 100;// including margin
  const totalEventContainerWidth =   eventWidth * (totalEvent-1);

  const mobYearWidth = 84; // including margin
  const totalMobYearWidth = mobYearWidth  ;
  const totalMobYearContainerWidth =   mobYearWidth * (totalEvent-1)

  const yearWidth = 93; // including margin
  const totalyearWidth = yearWidth  ;
  const totalYearContainerWidth =   yearWidth * (totalEvent-1)


  const timelineSlidePrev = ()=>{
    const nextImg = currentEventIndex[0] <= -totalEventContainerWidth ? -totalEventContainerWidth  : currentEventIndex[0] - eventWidth;
    const nextYear = currentEventIndex[1] <= -totalYearContainerWidth ? -totalYearContainerWidth  : currentEventIndex[1] - totalyearWidth;
    const mobnextYear = currentEventIndex[2] <= -totalMobYearContainerWidth ? -totalMobYearContainerWidth  : currentEventIndex[2] - totalMobYearWidth;
    setCurrentEventIndex([nextImg,nextYear,mobnextYear]);
    console.log(currentEventIndex[0],currentEventIndex[1],currentEventIndex[2]);

  }
  const timelineSlideNext = ()=>{
    const nextImg = currentEventIndex[0] < 0 ?  currentEventIndex[0] + eventWidth :  0;
    const nextYear = currentEventIndex[1] < 0 ?  currentEventIndex[1] + totalyearWidth :  0;
    const mobnextYear= currentEventIndex[2] < 0 ?  currentEventIndex[2] + totalMobYearWidth : 0;
    setCurrentEventIndex([nextImg,nextYear,mobnextYear]);
    console.log(currentEventIndex[0],currentEventIndex[1],currentEventIndex[2]);

  }
  
  
  return (
    <div className='timeline-container'>

      
      <div className="main-timeline">
      {timeline_content.map((timeline) => (<>
        <div className="timeline" style={{top: currentEventIndex[0] + "%"}}>
          <div className="timeline-year">
            <span>{timeline.event_year}</span>
          </div>
          <div className="timeline-content-right">
          <img src={timeline.event_img} alt="time-imag" />   
          <p>{timeline.event_text1}</p>       
          
                 
          <p>{timeline.event_text2}</p> 
          
        </div>
        </div>
          </>))}      
      </div>
      <div className="side-timeline">
        <div className="timeline-btn">
          <span  onClick={timelineSlideNext}>&gt;</span><span onClick={timelineSlidePrev}>&lt;</span>
        </div>
        <div className='side-timeline-year'>
      {timeline_content.map((timeline) => (<>
        <span className='timeline-year-desktop ' style={{top: currentEventIndex[1] + "%"}} >{timeline.event_year}</span>
        <span className='timeline-year-mob' style={{left: currentEventIndex[2] + "%"}} >{timeline.event_year}</span>

        </>))}
        </div>
      </div>

    </div>
  )
}

export default Timeline_Slider; 