import React from 'react'
import NavbarPage from '../components/Navbar'

const ServicesPage = () => {
  return (<>
  <NavbarPage/>
  
    <div>Services</div>
  </>
  )
}

export default ServicesPage;