import React, { useState } from 'react'
import './Products.css'

import {  MDBRow, MDBCol, MDBIcon, MDBBtn , MDBAnimation} from "mdbreact";

// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/js/all.min.js'

const AccordionItem = (props) => {

    const [visibility, setvisibility] = useState(false,[]);
    // const [visibility, setvisibility] = useState(true,[]);

    const products = props.accrodionText.products;
    // const productsList = products.map((service,count) => console.log("count is:",service, products.length-count>1? "count is:"+count : "count ==",products.length))
    // console.log(productsList)

    const activeStatus = visibility ? 'active':'';
    const buttonStatus = visibility ? '':'active';
   

    const handleToggleVisibility = ()=> {
        setvisibility(!visibility)
    }
    


  return (<>
 
    <button className={`accordion_content  accordion_button ${buttonStatus} `} onClick={()=> handleToggleVisibility()}>
        <img src={props.accrodionText.img_URL} className='gearIcon iconImg'  id={`img-${props.accrodionText.id}`} alt='image'/>
            {props.accrodionText.label} <p>{props.accrodionText.value}</p> <span className='arrow-down icon' onClick={()=> handleToggleVisibility()}>&darr;</span>
    </button>
       
    <div className={`accordion_container .grad  accordion_content ${activeStatus}`}>
        
        <div className={`row ${activeStatus} `}>
            <div className={`column `}>
                {props.accrodionText.id}
                <h3 className={``}>{props.accrodionText.label}</h3>
                <p className={` accordion_para `}>{props.accrodionText.value}</p>
                <div className={`accordion_para `}>
                    {products.map((service,count) =>  (<> <span className='accordion_span'>{service}</span> {products.length - count > 1? <div className='white-divider' />:"" } </>) )}
                </div>
            </div>
            <div className={`column `}>
                <div>
                    <img src={props.accrodionText.img_URL} className={` gearImg Img `} alt='image'/>
                </div>
            </div>
        </div>
        <div className={`accordion_content row-2 ${activeStatus}`}>
            <button className={` btn white-border `}>Know More</button>
            <span className={`arrow-up icon `} onClick = { () => handleToggleVisibility()} >&uarr;</span>
        </div>
    </div>
    
    
    </>)
}

export default AccordionItem