import React from "react";
import NavbarPage from "../components/Navbar.js";
import Banner_img from "../components/Banner_Img/Banner_img.jsx";
import "./WhoWeAre.css";
import Img_Carousel from "../components/Img_Carousel/Img_Carousel.jsx";
import Timeline_Slider from "../components/Timline_Slider/Timeline_Slider.jsx";
import Footer from "../components/Footer/Footer.jsx";

const WhoWeAre = () => {


  const img_path1 =
    "https://images.unsplash.com/photo-1421986527537-888d998adb74?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY4NTAzMDU3Mw&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080";

    const img_path2 = "https://images.unsplash.com/photo-1663042363565-adbd4443847b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY4NjIxOTg4Mw&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080";

    const img_path3 = "https://images.unsplash.com/photo-1528502668750-88ba58015b2f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY4MjM1NDQ0Mg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080";

    const img_Carousel_arr = [
      {id:1, title : "Newsroom", text:"Stay connected and up to date with our events and announcements", image : img_path1},
      {id:2, title : "CSR", text:"Our efforts are directed toward education, skilling, employment and entrepreneurship", image : img_path2},
      {id:3, title : "Diversity, Equality, Inclusion", text:"A world where we can be, belong, become", image : img_path3},
      {id:4, title : "4. lorem ispem", text:"lorem ipsum text", image: img_path1},
      {id:5, title : "5. lorem ispem", text:"lorem ipsum text", image : img_path2},
      {id:6, title : "6. lorem ispem", text:"lorem ipsum text", image : img_path3},
    ];

    const timeline_content = [
      {
        id:1,
        event_year:2023,
        event_text1: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo quo nulla hic nostrum qui dicta quos quasi architecto, consequatur eligendi mollitia, reiciendis accusamus corrupti saepe provident sit possimus! Inventore, eveniet.",
        event_text2:"Lorem ipsum dolor sit, amet ",
        event_img: img_path1},
      {
        id:1,
        event_year:2022,
        event_text1: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo quo nulla hic nostrum qui dicta quos quasi architecto, consequatur eligendi mollitia, reiciendis accusamus corrupti saepe provident sit possimus! Inventore, eveniet.",
        event_text2:"Lorem ipsum dolor sit, amet ",
        event_img: img_path2},
      {
        id:1,
        event_year:2021,
        event_text1: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo quo nulla hic nostrum qui dicta quos quasi architecto, consequatur eligendi mollitia, reiciendis accusamus corrupti saepe provident sit possimus! Inventore, eveniet.",
        event_text2:"Lorem ipsum dolor sit, amet ",
        event_img: img_path3},
      {
        id:1,
        event_year:2020,
        event_text1: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo quo nulla hic nostrum qui dicta quos quasi architecto, consequatur eligendi mollitia, reiciendis accusamus corrupti saepe provident sit possimus! Inventore, eveniet.",
        event_text2:"Lorem ipsum dolor sit, amet ",
        event_img: img_path1},
    ];


  return (
    <>
      <NavbarPage />
      <div className="whoweare-page">
      <div className="section-banner">
        <Banner_img path={img_path1} />
      </div>
      <div className="section-container ">
        <div className="section-text">
          <h4>
            Building greater futures through innovation and collective
            knowledge.
          </h4>
          <p>
            Webokit is an IT services, consulting and business solutions
            organization that has been partnering with many of the world’s
            largest businesses. We believe innovation and collective knowledge
            can transform all our futures with greater purpose.
          </p>
        </div>
        <div className="section-img">
          <img src={img_path2} alt="building future" srcset="" />
        </div>
      </div>
      <div className="section-glance">
        <p>Webokit at a glance</p>
        <img src={img_path3} alt="smileing kid" />
      </div>
      <div className="section-carousel">
        <p>Get to know us better</p>
        <Img_Carousel img_Carousel_arr={img_Carousel_arr}/> 
      </div>
      <div className="section-timeline">
        TIMELINE
        <span>RoadMap</span>
        <Timeline_Slider timeline_content ={timeline_content}/>

      </div>
      <div className="section-investors">
        <img src={img_path1} alt="" />
        
          <span>investors</span>
          <span>Positioned for long-term sustainable growth</span>
          <p>Webokit is seen as a benchmark in its outreach to investors, in its transparency and disclosures, publicly communicating its strategy, risks and opportunities, reducing information asymmetries and enabling fair valuation of the stock.</p>
          <button className="read-more-btn">Read more <i>&rarr;</i></button>
      
      </div>
      
          
    <div className="footer-whoweare">
      <div>
        <span>Transformation starts here</span>
        <p>Reimagine your future.</p>
        <button className="connect-button">Connect with us</button>
      </div>
      <hr/>
      <div>
        <h2>FIND OUT MORE</h2>
        <button className="webokit-button">What we do <i className="icon">&rarr;</i></button>
        <button className="webokit-button">The webokit way <i className="icon">&rarr;</i></button>
      </div>
    </div>
    </div>
    <div>

    <Footer/>
    </div>


      
    </>
  );
};

export default WhoWeAre;
