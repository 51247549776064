import {createReducer} from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
    isLoggedIn :false
}


export const updateLoggedInStatus = (state= INITIAL_STATE,{isLoggedIn})=>{
    return {...state, isLoggedIn}
}

export const HANDLERS = {
    [Types.UPDATE_LOGGED_IN_STATUS]: updateLoggedInStatus
}

export default createReducer(INITIAL_STATE, HANDLERS);


// export const HANDLERS = {
//     [Types.UPDATE_LOGGED_IN_STATUS]: updateLoggedInStatus,
//     [Types.UPDATE_WHOLESALER_ID]: updateWholeSalerID
// };



// export const updateWholeSalerID = (state = INITIAL_STATE, { wholeSalerID }) => {
//     return { ...state, wholeSalerID }
// }

// export const HANDLERS = {
//     [Types.UPDATE_LOGGED_IN_STATUS]: updateLoggedInStatus,
//     [Types.UPDATE_WHOLESALER_ID]: updateWholeSalerID
// };

// export default createReducer(INITIAL_STATE, HANDLERS);