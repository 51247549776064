import React from 'react'
import { useState,useEffect } from 'react';
import "./Img_Carousel.css"



const Img_Carousel = ({img_Carousel_arr}) => {

  // window resize detection -- not in use

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  console.log(windowSize[0],"is window size")

  // carousel box variables
  
  
  const [currentImageIndex, setCurrentImageIndex] = useState([0,0]);
  
  

  const totalImg = img_Carousel_arr.length;


  const imgWidth = 27;
  const imgmargin = 1;
  const imgOnDisplay = 3
  const totalImgWidth = imgWidth + imgmargin ;
  const totalImgContainerWidth =   imgWidth * (totalImg);
  const displayImgwidth = imgOnDisplay*totalImgWidth;
  const imgtodisplace = totalImgContainerWidth - displayImgwidth  ;

  const mobImgWidth = 99.5;
  const mobImgmargin = 1;
  const totalMobImgWidth = mobImgWidth + mobImgmargin ;
  const totalMobImgContainerWidth =   mobImgWidth * (totalImg-1)
  

  

  const showNextImage = () => {
    
      const nextImg = (currentImageIndex[0] >= - imgtodisplace ? currentImageIndex[0] - totalImgWidth : - imgtodisplace ) ;
      const mobnextImg = (currentImageIndex[1] > - totalMobImgContainerWidth ? currentImageIndex[1] - totalMobImgWidth : - totalMobImgContainerWidth ) ;
      setCurrentImageIndex([nextImg,mobnextImg]);
    
  };

  const ShowPrevImage = () => {
    
      const prevImg = currentImageIndex[0] >= 0 ? 0 : currentImageIndex[0] + totalImgWidth ;
      const mobprevImg = currentImageIndex[1] >= 0 ? 0 : currentImageIndex[1] + totalMobImgWidth ;
      setCurrentImageIndex([prevImg,mobprevImg]);
    
    
  };
  return (
    <div>
      <div className="div-img-carousel">
      <button className='prevBtn' onClick={ShowPrevImage}>&larr;</button>
      <button className='nextBtn' onClick={showNextImage}>&rarr;</button>
        <div className="carousel">
          {img_Carousel_arr.map((carouselArr) => (
            <>
            <div className="carousel-box" style={{ left: currentImageIndex[0] + "vw",  backgroundImage: `url(${carouselArr.image})`, maskImage: `url(${carouselArr.image})`, webkitMaskImage: `url(${carouselArr.image})   ` }}>
            
            <p className='carousel-title'>{carouselArr.title}</p>
            <p className='carousel-text'>
              {carouselArr.text}
            </p>
            <span className='read-more-btn'><i className='icon'>&rarr;</i> Read More</span>
          </div>
            <div className="carousel-box mob" style={{ left: currentImageIndex[1] + "vw",  backgroundImage: `url(${carouselArr.image})`, maskImage: `url(${carouselArr.image})`, webkitMaskImage: `url(${carouselArr.image})   ` }}>
            
            <p className='carousel-title'>{carouselArr.title}</p>
            <p className='carousel-text'>
              {carouselArr.text}
            </p>
            <span className='read-more-btn'><i className='icon'>&rarr;</i> Read More</span>
          </div>

            </>
          )
           )}
          
        </div>
      </div>
    </div>
  )
}

export default Img_Carousel