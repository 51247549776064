import React, { lazy, Suspense,useState,useEffect,createRef } from 'react';
import NavbarPage from '../components/Navbar';
import CarouselPage from '../components/Carousel';
// import CarouselPage from '../components/Carousel';
import BallTrail from '../components/BallTrail/BallTrail.js';
import ContactForm from '../components/Forms/ContactForm.js';
import Services from '../components/Services/Services.js';
import About from '../components/About/About.js';
import { render } from 'react-dom';
import { MDBBtn } from 'mdbreact';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LoginUser, updateLoggedInStatus } from "../components/Users/actions";
import Products from '../components/Product/Products';
import Footer from '../components/Footer/Footer';
import WhatWeDo from './WhatWeDo';
import WhoWeAre from './WhoWeAre';
import Careers from './Careers';

// import './App.css';
// const LazyComponent = lazy(()=> import( '../components/Services/Services.js') );

// function HomePage() {
//   const[Carousel,Service]= useState(true)

//   useEffect(() => {
//     // setState(state => ({...state, Service:true}) );
//   // Service(true);
//   // Service(true)
//   Service(state => !state);
//    console.log(Service);
//    alert(Service);
//   },[]
//   );
// console.log(Service);
//   return (
//     <div className="App">

      {/* <NavbarPage/>
      <CarouselPage/>
      <Services/> */}
      
      {/* <Suspense fallback={<div>Loading...</div>}>

        <LazyComponent/>
      </Suspense> */}    
      {/* <BallTrail/> */}

      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
//     </div>
//   );
// }





class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.dropRef = createRef();
    this.handleDropClickOutside = this.handleDropClickOutside.bind(this);
    this.state = {
      // isLoggedIn:false,
      // Carousel:true,
      // Service: false,
      contactBool: true,
      isDropHovered: false,
    };
  }

  componentDidMount(){
    document.addEventListener('click',this.handleDropClickOutside)
    console.log("handleDropClickOutside is mounted")
    
    // this.props.updateLoggedInStatusConnect(true)
   
      console.log("isLoggedIn "+this.props.isLoggedIn);
  
    
    // console.log(this.state.Service);
    // this.setState({Service:true});
  };

  componentWillUnmount(){
    document.removeEventListener('click',this.handleDropClickOutside);
    console.log("handleDropClickOutside is Unmounted")
    

  };

  handleLoginClick() {
    // axios.post('http://localhost:3000/api/users/login',{
    //   "email":"abcdefg@gmail.com",
    //   "password":"abcdefg@123"
    // })
    
    
    this.props.LoginUserConnect({
      "email":"abcdefg@gmail.com","password":"abcdefg@123"})
    .then(response=>{
      console.log(this);
      this.props.updateLoggedInStatusConnect(true)
      console.log(this.props.isLoggedIn);
     
      // .then(response=>{
      //   const {isLoggedIn}=this.props;
      //      console.log(isLoggedIn);
      // }
      // )
      console.log(this.props.updateLoggedInStatusConnect,"i am prop");
    }).catch(function(error){
      console.log(error);
    })
    
    
    // console.log(this.state.isLoggedIn,"i am login");
    // this.setState({isLoggedIn: true});
  }

  handleLogoutClick() {
    // this.setState({isLoggedIn: false});
  }

  



//  handledropClick = () =>{
//   const contactvar = this.state.contactBool;
//   if(contactvar){

//     this.setState({
//       contactBool: !contactvar,
//     });
//   }else{
//     this.setState({
//       contactBool: !contactvar,
//     });

//   }

//   console.log("handledropClick contactbool is : "+ this.state.contactBool);
//  }

handledropClick = () =>{
  const contactvar = this.state.contactBool;
  if(contactvar){
    this.setState({
      contactBool: !contactvar,
    });
  }else{
    this.setState({
      contactBool: !contactvar,
  
    });
  }
  console.log("handledropClick contactbool is : "+ this.state.contactBool);
 }


//  functions to detect hover on Navbar and Dropdown
 handleDropMouseEnter =() => {
   this.setState({
     isDropHovered:true,
   });
   console.log("handleDropMouseEnter is called");
 }
 handleDropMouseLeave =() => {
   this.setState({
     isDropHovered:false,
   });
   console.log("handleDropMouseLeave is called");
 }

 // function to change ContactBool state to hide contact-form
handledropClick = () =>{
  this.setState(prevState => ({
    contactBool: !prevState.contactBool,
  }));
  console.log("handleDropClick: contactBool value is "+ this.state.contactBool);

}
  
// function to check click is on dropdown menu using dropRef
handleDropClickOutside =(e) => {
  // console.log(this.dropRef.current.contains(e.target))
  if(!this.state.contactBool){
  

    if(  this.dropRef.current && !this.dropRef.current.contains(e.target)){
      // this.handledropClick();
      console.log("handleDropClickOutside: if part - ContactBool: true")
      this.setState({        contactBool:true,      })
      
     
    }else{
      this.setState({        contactBool:false,      })

      console.log("handleDropClickOutside : else part - contactBool: false")
      
      
    }
    
  }
  
};

 



  render() {
    // const updateLoggedInStatusConnect;
    // const LoginUserConnect;
    // const isLoggedIn = this.state.isLoggedIn;
    // let button;
    // if (isLoggedIn) {
    //   button = <LogoutButton onClick={this.handleLogoutClick} />;
    // } else {
    //   button = <LoginButton onClick={this.handleLoginClick} />;
    // }
    
    // const ServiceValue = this.state.Service;
    return (
      
      <div className="App">
        {/* <MDBBtn onClick={this.handleLoginClick}>LOGIN</MDBBtn> */}
        
        <NavbarPage 
        isDropHovered={this.state.isDropHovered}
        handleDropMouseEnter={this.handleDropMouseEnter}
        handleDropMouseLeave={this.handleDropMouseLeave}
        contactBool = {this.state.contactBool}
        // handledropClick = {this.handledropClick}
        // handledropClick1 = {this.handledropClick1}
        dropRef={this.dropRef}

         />
      <CarouselPage
       contactBool ={this.state.contactBool} 
       isDropHovered={this.state.isDropHovered}  />
      <Services/>
      {/* {console.log(this.state.Service)} */}
      {/* {ServiceValue ? <Services/> : <CarouselPage/>} */}
      
      <About/>
      <Products/> 
      <Footer/>

      


      {/* </Routes> */}
       
      </div>
    );
  }
}

const mapStateToProps =({user: { isLoggedIn }}) => ({
isLoggedIn

});

const mapDispatchToProps =(dispatch) => bindActionCreators({
LoginUserConnect: LoginUser,
updateLoggedInStatusConnect : updateLoggedInStatus
},dispatch)
export default connect(mapStateToProps,mapDispatchToProps)(HomePage);