import React from 'react';
import "./Banner_img.css";

const Banner_img = (props) => {
  return (
    <div className='banner-div'>
      <img className='banner-img' src={props.path} alt="banner" />
    </div>
  )
}

export default Banner_img;