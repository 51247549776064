import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBMask ,MDBView} from 'mdbreact';
import Aboutimage from '../../images/aboutimage.jpg';
// import BouncingBall from "../Bouncingball/Bouncingball.js";
import RectAnimation from "../AnimatedRect/Animation.js";
import Ball from "../Bouncingball/Bouncingball1.js";
import './About.css';
import Carousel_3d from "../3d_Carousel/Carousel-3d";

const AboutSection = () => {
return (
<div className="Aboutcontainer" >
    <h3 className="aboutheading">WHO&nbsp; 
        <span className="aboutsubheading">WE ARE </span>
    </h3>

    <div class="aboutline"></div>
  <MDBRow>
  {/* <QuadBounce
      duration={1150}
      start={0}
      end={160}
    >
      { value => <Ball y={value} /> }
    </QuadBounce> */}
    
      <MDBCol className="aboutimagestyle" md="5" xl="5" lg="5">
        {/* <Aboutimage/> */}
    <MDBView className="imageshape">
    <img className="d-block w-100"  src={Aboutimage}/>
    <MDBMask overlay="black-light" />
    </MDBView>
    {/* <RectAnimation/> */}
    {/* <BouncingBall duration={1150}
      start={0}
      end={160}>
      { value => <Ball y={value} /> }
    </BouncingBall> */}

  
      </MDBCol>
      <MDBCol className="aboutintroduction" md="6" xl="6" lg="6">
      <div className="aboutusbuttonsection">
      <MDBBtn className="aboutusbutton">About Us</MDBBtn>
      </div>
        <div className="aboutparagraphsection">
            <p className="aboutparagraph">
            We specialize in providing innovative technology solutions for businesses of all sizes, from startups to large enterprises. With our team of experienced professionals, we are committed to delivering high-quality services that help our clients stay ahead of the competition.

            Our areas of expertise include software development, cloud computing, cybersecurity, data analytics, and digital transformation. We strive to understand our clients' unique needs and tailor our solutions to meet their specific requirements.
            </p>
            <p className="aboutparagraph">
            At our company, we believe in fostering a culture of collaboration and creativity. We encourage our team members to think outside the box and to constantly learn and grow in their fields.

            Our goal is to build long-term partnerships with our clients and to provide them with the tools and support they need to succeed in today's fast-paced digital landscape.

            Thank you for considering our IT company for your business needs. We look forward to the opportunity to work with you and to help you achieve your goals.
            </p>
      <div className="knowmoresection">
      <MDBBtn className="knowmorebutton">Know More About Us</MDBBtn>
      </div>
        </div>
      </MDBCol>
     
    {/* <MDBCol md="6" className="">
      <form>
        <p className="h5 text-center mb-4 formheading">Happy To Hear You</p>
        <div className="grey-text">
          <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
            success="right" />
          <MDBInput label="Your email" icon="envelope" group type="email" validate error="wrong"
            success="right" />
          <MDBInput label="Your's Contact Number" icon="tag" group type="text" validate error="wrong" success="right" />
          <MDBInput type="textarea" rows="2" label="What you are looking for" icon="pencil-alt" />
        </div>
        <div className="text-center">
          <MDBBtn outline >
            Send
            <MDBIcon far icon="paper-plane" className="ml-1" />
          </MDBBtn>
        </div>
      </form>
    </MDBCol> */}
  </MDBRow>
  <Carousel_3d/>
</div>
);
};

export default AboutSection;