import React from 'react'
import NavbarPage from '../components/Navbar';

const ContactPage = () => {
  return (<>
    <NavbarPage/>
    <div>ContactPage</div></>
  )
}

export default ContactPage;