export default api=>{

    const UsersLogin= (params) =>{
       return api.post("/api/users/login",params)
    } 

   return {UsersLogin};
}


// const loginSeller = (params) => {
//     return api.post("/api/wholesalers/login", params);
// }