import Types from "./types";
import {UserService} from "../../resources";


const updateLoggedInStatus = isLoggedIn =>({
    type:Types.UPDATE_LOGGED_IN_STATUS,
    isLoggedIn
})

const LoginUser = (payload)=> ()=>{
        return new Promise((resolve,reject)=>{
            UserService.UsersLogin(payload)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
}
// const loginSeller = (payload) => () => {
//     return new Promise((resolve,reject) => {
//         WholeSellerService.loginSeller(payload)
//         .then(response => resolve(response))
//         .catch(error => reject(error))
//     })
// }

export {
    updateLoggedInStatus,
    LoginUser
}