import React, { useState, useEffect } from 'react'
import './Products.css'
import AccordionItem from './AccordionItem'
import MobileView from './mobile_view';
import { useRef } from 'react';


const Accordion = (props) => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  console.log(windowSize[0],"is window size")
  

  // let box = document.querySelector('.product-container');
  const btnPressPrev = () =>{
    let box = document.querySelector('.accordion');
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
    // console.log(width)
    // console.log(box.scrollLeft + width)

}
const btnPressNext = () =>{
    let box = document.querySelector('.accordion');
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
    // console.log(width)
    // console.log(box.scrollLeft + width)
    
}
  return (<>
  <div className=' d-flex justify-content-around'>
  <button className='pre-btn d-lg-none d-m-none d-sm-flex' onClick={btnPressPrev}>&larr;</button>
        <button className='next-btn d-lg-none d-m-none d-sm-flex' onClick={btnPressNext}>&rarr;</button>

  </div>
  <div className= " product-carousel">
    <div className='accordion '>
    
        {props.accrodionTexts.map((accrodionText)=>
        windowSize[0] <= 480? <MobileView key={accrodionText.label}  accrodionText={accrodionText} />: 
        < AccordionItem key={accrodionText.label}  accrodionText={accrodionText} />
        )}
    </div>
    </div>
    
    

    
    </>)
}

export default Accordion



