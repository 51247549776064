import React from 'react'

const Wave = () => {
  return (
    <>
      <div className="line-body-wrapper">
        <svg>
          <path  ></path>
        </svg>
        <svg>
          <path></path>
        </svg>
        <svg>
          <path></path>
        </svg>
      </div>
    </>
    
  )
}

export default Wave