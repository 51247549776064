import React, { useState } from 'react'
import NavbarPage from '../components/Navbar'
import Carousel_3d from '../components/3d_Carousel/Carousel-3d'
import Footer from '../components/Footer/Footer'
import '../screens/WhatWeDo.css'
import '../screens/WhatWeDo_mob.css'


const WhatWeDo = () => {
  const initialTiles = [
    { id: 1, title:"Cloud",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
    { id: 2, title: "Cognitive Business Operations",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
    { id: 3, title: "Consulting",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
    { id: 4, title: "Data & Analytics",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
    { id: 5, title: "Enterprise Solutions",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
    { id: 6, title: "IoT Digital Engineering",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
    { id: 7, title: "CyberSecurity",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
    { id: 8, title: "Network Solutions and Services",image: 'https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80' },
  ];

  const [tiles, setTiles] = useState(initialTiles);
  const [showMoreTiles, setShowMoreTiles] = useState(false);
 
  const handleViewMore = () =>{
    setShowMoreTiles(!showMoreTiles);
  }



  const [showMenu, setShowMenu] = useState([false, false, false]);

  const handleClick = (index) => {
    setShowMenu((prev) => {
      return prev.map((val, i) => (i === index ? !val : false));
    });
  };
  return (<>
    <NavbarPage/>
    <div className="section-banner-img">
      <div className="banner-img"  />
      {/* <img className="banner-img" src="https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" alt="3" /> */}
      <p>What We Do</p>
      <div/>
     
    </div>
    <div className="section-container">
      <div className="row">
        <div className="col"><h3>Believes In Creating The Impact</h3><p>At Webotic, we help businesses transform through technology. We help them make a meaningful difference to their customers, and to the communities they serve.</p></div>
        <div className="col"><img src="https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" alt="Image of people holding up signs"/></div>
      </div>
      <div className="row">
        <div className="col"><img src="https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" alt="Image of people holding up signs"/></div>
        <div className="col"><h3>Believes In Creating The Impact</h3><p>At Webiotic, we help businesses transform through technology. We help them make a meaningful difference to their customers, and to the communities they serve.</p></div>
      </div>
    </div>
    <div className="section-services">
      <h5>services</h5>
      <div className="services-box">
        
        { tiles.map(tile =>(
          tile.id <= 4 ?
          <div key={tile.id} className="service">
            <div className="img-container" style={{background_image: `tile.image`}}>
              <h6>{tile.title}</h6>
              {/* <img src="https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" /> */}
            </div>
          </div> 
        : showMoreTiles ? <div key={tile.id} className="service">
        <div className="img-container" style={{background_image: `tile.image`}}>
          <h6>{tile.title}</h6>
          {/* <img src="https://plus.unsplash.com/premium_photo-1674777843441-04dd6cd672ee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80" /> */}
        </div>
      </div> : ''
        ))}
      
     
      </div>
      <div className="view-more-btn" onClick={handleViewMore}>View More <span className={` icon `}  >&darr;</span></div>
    </div>

   
  </>
  )}

export default WhatWeDo;