import React, { useState,useEffect } from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";
// import website-banner from '../images/website-banner.jpg';
import WebsiteLogo from '../images/website-banner2.jpg';
import NavbarPage from './Navbar';
import BallTrail from './BallTrail/BallTrail.js';
import ContactForm from './Forms/ContactForm.js';
import Services from '../components/Services/Services.js';

const CarouselPage = (props) => {


  console.log(props)
  // const [bgHover, setBgHover] = useState(false)
  // const handleBgOver =() =>{
  //   setBgHover(true);
  //   console.log("bgHover is over: "+ bgHover)
  // }
  // const handleBgOut =() =>{
  //   setBgHover(false);
  //   console.log("bgHover is over: "+ bgHover)
  // }

  
  return (
    <div className="Carouselcontainer" style={{zIndex:0}}>
    {/* <MDBContainer container="Carouselcontainer"> */}
    
      <MDBCarousel
      activeItem={1}
      length={1}
      showControls={true}
      showIndicators={false}
      className="z-depth-1"
      // onMouseOver={handleBgOver} onMouseOut={handleBgOut}
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1"  >
          <MDBView>
          <BallTrail/>
 {/* {props.contactBool &&  */}
 {!props.isDropHovered && 
          <ContactForm 
          // mouseHover = {props.mouseHover} bgHover={bgHover} dropdownHover = {props.dropdownHover}
           />
}
            <img
              className="d-block w-100 bannerImage"
              src={WebsiteLogo}
              alt="First slide"
            />
            {/* <BallTrail/> */}
          <MDBMask overlay="black-light" />
          </MDBView>
          
          {/* <MDBCarouselCaption>
            <h3 className="h3-responsive">Light mask</h3>
            <p>First text</p>
          </MDBCarouselCaption> */}
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2" >
          <MDBView>
            <img
              className="d-block w-100"
              src="https://mdbootstrap.com/img/Photos/Slides/img%20(6).jpg"
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className="h3-responsive">Strong mask</h3>
            <p>Second text</p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src="https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg"
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            <h3 className="h3-responsive">Slight Mast</h3>
            <p>Third text</p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    {/* </MDBContainer> */}
    {/* <Services/> */}
    </div>
  );
}

export default CarouselPage;