import React from 'react'
import '../3d_Carousel/Carousel-3d.css'

const Carousel_3d = () => {
    const {items} = {
        items:[
          {id:1, title: 'item_1', urL:'https://loremflickr.com/500/501'},
          {id:2, title: 'item_2' , urL:'https://loremflickr.com/500/502'},
          {id:3, title: 'item_3' , urL:'https://loremflickr.com/640/363'},
          {id:4, title: 'item_4' , urL:'https://loremflickr.com/640/364'},
          {id:5, title: 'item_5' , urL:'https://loremflickr.com/640/365'},
          {id:6, title: 'item_6' , urL:'https://loremflickr.com/640/366'},
          {id:7, title: 'item_7' , urL:'https://loremflickr.com/640/367'},
          {id:8, title: 'item_8' , urL:'https://loremflickr.com/640/368'},
        ]
      }




const click = (event) => {
  const buttonsWrapper = document.querySelector(".map");
const slides = document.querySelector(".carousel2_face");
  
  event = undefined ?  console.log('undefined') : console.log(event)
  if(event.nodeName === 'BUTTON'){
    console.log('button class is ', event);
  }
  // buttonsWrapper;
  
  
  
  return;
}


  return (<>
    <div className='container2'>
        <div className='carousel2'>

           

           {items.map(item => < div key={item.id} className='carousel2_face' ><span>{item.title}</span>
           </div>)}
        </div>
    </div>
    {/* <div className='map pagination'> {items.map(item => <button className={item.title} ></button> 
    )}</div> */}

</>

  )
}

export default Carousel_3d;