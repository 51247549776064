import React, { lazy, Suspense,useState,useEffect } from 'react';
import NavbarPage from '../components/Navbar';
import CarouselPage from '../components/Carousel';
// import CarouselPage from '../components/Carousel';
import BallTrail from '../components/BallTrail/BallTrail.js';
import ContactForm from '../components/Forms/ContactForm.js';
import Services from '../components/Services/Services.js';
import About from '../components/About/About.js';
import { render } from 'react-dom';
import Products from '../components/Product/Products';
// import './App.css';
// const LazyComponent = lazy(()=> import( '../components/Services/Services.js') );

// function HomePage() {
//   const[Carousel,Service]= useState(true)

//   useEffect(() => {
//     // setState(state => ({...state, Service:true}) );
//   // Service(true);
//   // Service(true)
//   Service(state => !state);
//    console.log(Service);
//    alert(Service);
//   },[]
//   );
// console.log(Service);
//   return (
//     <div className="App">

      {/* <NavbarPage/>
      <CarouselPage/>
      <Services/> */}
      
      {/* <Suspense fallback={<div>Loading...</div>}>

        <LazyComponent/>
      </Suspense> */}    
      {/* <BallTrail/> */}

      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
//     </div>
//   );
// }





class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);

    this.state = {
      Carousel:true,
      Service: false,
      mouseHover: false,
    };
  }

  componentDidMount(){
    console.log(this.state.Service);
    this.setState({Service:true});
  }
  handleLoginClick() {
    this.setState({isLoggedIn: true});
  }

  handleLogoutClick() {
    this.setState({isLoggedIn: false});
  }

  
handleMouseOver = ()=>{
  this.setState({
   mouseHover: true,
 });
 console.log( "mouseHover is in :" + this.state.mouseHover)
   
 }
 handleMouseOut = () =>{
   this.setState({
     mouseHover: false,
    });
    console.log( "mouseHover is  :" + this.state.mouseHover)
 }

  render() {
    // const isLoggedIn = this.state.isLoggedIn;
    // let button;
    // if (isLoggedIn) {
    //   button = <LogoutButton onClick={this.handleLogoutClick} />;
    // } else {
    //   button = <LoginButton onClick={this.handleLoginClick} />;
    // }
    
    const ServiceValue = this.state.Service;
    // const mouseHover = this.state.mouseHovering;
    console.log("demopage", this.state.mouseHover);

    return (
      <div className="App">
        <NavbarPage mouseHover = {this.state.mouseHover} handleMouseOver = {this.handleMouseOver} handleMouseOut = {this.handleMouseOut} />
      <CarouselPage mouseHover = {this.state.mouseHover} />
      {console.log(this.state.Service)}
      {ServiceValue ? <Services/> : <CarouselPage/>}
      <About/>
      <Products/>
       
      </div>
    );
  }
}

export default HomePage;