import React from 'react'
// import gear_icon_trans_removebg from '../../images/gear-icon-trans-removebg.png'
import setting_colorful from '../../images/setting-colorful.png'
import bulb_grad from '../../images/bulb-grad.png'
import Accordion from './Accordion'
import Wave from './Wave'


const Products = () => {
    const AccrodionTexts = [
    {
        id: ' 01',
        label: 'Digital Products Development',
        value: 'Determined to establish business in an agile environment by streamlining custom software development processes for a seamless experience and measurable results.',
   
        products:[
        'Mobile App Development','Website/PWA Development','Blockchain Development',
    ],
        img_URL:setting_colorful,
    },
    {
        id: '02',
        label: 'Staff Augmentation',
        value: 'Determined to establish business in an agile environment by streamlining custom software development processes for a seamless experience and measurable results.',
        products: [
         'Hire Software Developers',
         'Hire React Native Developers',
         'Futter Developers',
         'ReactJS Developers',
        ],
    
    img_URL:setting_colorful,
    },
    {
        id:'03',
        label: 'Data Analytics and Management',
        value: 'Determined to establish business in an agile environment by streamlining custom software development processes for a seamless experience and measurable results.',
        products:[
         'Machine Learning',
         'Internet of Things',
         'Big Data',
        ],
        img_URL:setting_colorful,
    },
    {
        id:'04',
        label: 'Digital Strategy Consultation',
        value: 'Determined to establish business in an agile environment by streamlining custom software development processes for a seamless experience and measurable results.',
        products:[
            'End to End Consultation',
            '24X7 Support Operations',],
       
        img_URL:bulb_grad,
    }];
  return (
    <section className="my-5 mx-10  ">
        <div className='wave-container'>
        <Wave/>
        </div>
        <div className='products'> Services
    <h2 className="h1-responsive font-weight-bold text-center my-5">
        Building Scalable &
        Innovative Experiences
      </h2>
      <p className="lead grey-text w-responsive text-center mx-auto mb-5">
        Dedicated to designing and developing future-proof, significant, and secure digital products, centered to priortize user experience, and security, by aligning with your business objectives to drive growth and progress
      </p>
      
      </div>
      <Accordion accrodionTexts={AccrodionTexts} />
      
      

      
       </section>


  )
}

export default Products