import React from 'react'
import './mobileview.css'

const MobileView = (props) => {
    const products = props.accrodionText.products;
  return (
    <div className={`d-lg-none  sm_accordion_content  `}>
        
        <div className={`sm_row`}>
        <div className={`sm_column `}>
                <div>
                    <img src={props.accrodionText.img_URL} className={` gearImg sm_img  `} id={`img-${props.accrodionText.id}`} alt='image'/>
                </div>
            </div>
            <div className={`sm_column `}>
                {props.accrodionText.id}
                <h3 className={``}>{props.accrodionText.label}</h3>
                <p className={` sm_accordion_para `}>{props.accrodionText.value}</p>
                <div className={`sm_accordion_para `}>
                    {products.map((service,count) =>  (<> <span className='sm_accordion_span'>{service}</span> {products.length - count > 1? <div className='sm_white-divider' />:"" } </>) )}
                </div>
            </div>
            
        </div>
        <div className={`accordion_content row-2 active`}>
            <button className={` btn sm_white-border `}>Know More</button>
        </div>
        
        
        
    </div>
  )
}

export default MobileView