import React from 'react';
import logo from './logo.svg';
import NavbarPage from './components/Navbar';
import NotFound from './components/NotFound';
import HomePage from './screens/HomePage';
import DemoPage from './screens/DemoPage';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import { BrowserRouter, Route,Routes, Switch, Redirect, withRouter } from 'react-router-dom';
// import '../node_modules/mdbreact/docs/css/mdb.min.css';
import 'mdbreact/dist/css/mdb.css'; 
import './App.css';
import './style.css';
import FormState from './context/formControls/formState';
import Careers from './screens/Careers';
import WhoWeAre from './screens/WhoWeAre';
import WhatWeDo from './screens/WhatWeDo';
import ContactPage from './screens/ContactPage';
import ServicesPage from './screens/Services';
import OverviewPage from './screens/Overview';
// import Navbar from './components/Navbar.js';


function App() {
  return (
    <div className="App">
      <FormState>
      <BrowserRouter> 
      {/* <NavbarPage/>                                                                                                                         */}
      <Switch>
      <Route exact path={"/"} render={(props)=>(<HomePage {...props} />)}/> 
      <Route exact path="/demopage" render={(props)=>(<DemoPage {...props}/>)}/>
      <Route exact path="/whatwedo" component={WhatWeDo} />
      <Route exact path="/WhoWeAre" component={WhoWeAre} />
      <Route exact path="/careers" component={Careers} />
      <Route exact path="/contactpage" component={ContactPage} />
      <Route exact path="/ServicesPage" component={ServicesPage}/>
      <Route exact path="/OverviewPage" component={OverviewPage}/>
      <Route path='*' component={NotFound} />
      </Switch>
      </BrowserRouter>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>

      </FormState>
    </div>
  );
}

export default App;
