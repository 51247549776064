import React, {  useState, useEffect } from 'react'
import ParticleAnime from './ParticleAnime'

const FooterSphere = () => {

  const ruleData =[ 
    { text:'rule1',
     id:'1',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule2',
     id:'2',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule3',
     id:'3',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule4',
     id:'4',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule5',
     id:'5',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule6',
     id:'6',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule7',
     id:'7',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule8',
     id:'8',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
    { text:'rule9',
     id:'9',
     desc:"Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations."
},
]

  const initialRulesvalues = {
    rule1: false,
    rule2: false,
    rule3: false,
    rule4: false,
    rule5: false,
    rule6: false,
    rule7: false,
    rule8: false,
    rule9: false,
    
  };

  const [rulesValue, setRulesValue] = useState(initialRulesvalues);

 const [mouseEnter, setMouseEnter] = useState({value:false,name:"rule0"})

 useEffect(() => {
  console.log('name:',mouseEnter.name,', value:',mouseEnter.value)
  setRulesValue({...rulesValue, [mouseEnter.name]: mouseEnter.value})
  console.log(rulesValue);
  }, [mouseEnter])


  const rulesChangeHandler =(e) =>{
    setMouseEnter({value: !mouseEnter.value, name:e.target.id})
    console.log('name:',mouseEnter.name,', value:', mouseEnter.value)
  }

  return (<>
    <div className="sphereBody">
      <div className='scene'>
      <div className='drop-heading-wrapper'>
        <h1 className='drop-heading'><span>IAA SETI</span> Declaration of Principles</h1>
        <div className='drop-arrow1 '> <span className='drop-arrow1-span'>arrow</span> </div>
        <p className='drop-para'>The IAA, in an attempt to draw up universal guidelines on the immediate steps to be taken by the group or individual that makes a discovery, drafted the "Declaration of Principles Concerning Activities Following the Detection of Extraterrestrial Intelligence".The following has been endorsed by six international professional space societies and also constitutes an informal agreement among most of those carrying out SETI. The declaration proposes a set of nine post-detection protocols, listed below.</p>
        </div>
        <ParticleAnime/>
        <div className="sphere-wrapper">
          <div className="sphere">
            <div className="vertical-ring vr1"></div>
            <div className={`vertical-ring blue-ring v1 ${rulesValue.rule1 | rulesValue.rule8 ? 'show-ring vr1a' : "animate-ring" } `}></div>
            <div className="vertical-ring vr2"></div>
            <div className="vertical-ring vr3"></div>
            <div className="vertical-ring vr4"></div>
            <div className={` vertical-ring vr4a ${rulesValue.rule4 | rulesValue.rule6? 'opacity-1 blue-ring show-ring': "opacity-0"} `}></div>
            <div className="vertical-ring vr5"></div>
            <div className="vertical-ring vr6"></div>
            <div className={`vertical-ring vr7  ${rulesValue.rule5 ? '' : "blue-ring animate-ring" }`}></div>
            <div className={`vr7a  vertical-ring  ${rulesValue.rule5 ? 'show-ring blue-ring' : "" }`}></div>
            <div className="vertical-ring vr8"></div>
            <div className="vertical-ring vr9"></div>
            <div className="vertical-ring vr10"></div>
            <div className={` vertical-ring vr10 ${rulesValue.rule2 | rulesValue.rule7 ? 'opacity-1 orange-ring board-ring vr10a' : "opacity-0" }`}></div>
            <div className="vertical-ring vr11"></div>
            <div className="vertical-ring vr12"></div> 
            
            <div className="horizontal-ring hr1"></div> 
            <div className="horizontal-ring hr2"></div>
            {/* <div className="horizontal-ring hr3"></div>  */}
            <div className= {`horizontal-ring hr3 ${rulesValue.rule3 ? 'blue-ring' : ""} `}  ></div> 
            <div className="horizontal-ring hr4"></div>
            <div className="horizontal-ring hr5"></div>
            <div className={`horizontal-ring hr5 orange-ring ${rulesValue.rule9 ? 'show-ring-hr' : "animate-ring" }`}></div>
            <div className="horizontal-ring hr6"></div>
            <div className="horizontal-ring hr7"></div>
            <div className="horizontal-ring hr8"></div>
            <div className="horizontal-ring hr9"></div> 
          </div>
          <div className="sphere-axsis1"></div>
          <div className="sphere-axsis2"></div>
              
        </div>

      
      <div class={`${mouseEnter.value ? 'title-pulse-hide':''} title-pulse `}>
        POST<br/>DETECTION<br/><span class="title-pulse-span">PROTOCOLS</span>
      </div>
      <div className="rule-desc-wrapper">
        {/* {ruleData.map((data) => {
          const ruleId = "rule"+data.text;
          return(<div className={`rule-desc ${rulesValue.ruleId ? 'rule-desc-show':''}`}><br/><span className='rule-desc-span'>{data.text}</span><br/>{data.desc}</div> 
          )}
        )} */}
      
        <div className= {`rule-desc ${rulesValue.rule1 ? 'rule-desc-show' : ""}`}>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule2 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE2</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule3 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE3</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule4 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE4</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule5 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE5</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule6 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE6</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule7 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE7</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule8 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE8</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
        <div className= {`rule-desc ${rulesValue.rule9 ? 'rule-desc-show' : ""}`}><br/><span className='rule-desc-span'>RULE9</span><br/>Consultations on whether a message should be sent, and its content, should take place within the Committee on the Peaceful Uses of Outer Space of the United Nations and within other governmental and non-governmental organizations.</div>
      </div>
      
      
        </div>
        <div className="rule-wrapper">
          {ruleData.map((data) => <div className={` rule txt${data.id}`} id={data.text} onMouseEnter={rulesChangeHandler} onMouseLeave={rulesChangeHandler} >RULE {data.id}</div> )}
      </div>

      <p className='footer-para'>
These rules do not carry the force of law or any other regulatory power. Hence they can be ignored by public or private institutions should they choose to, without legal repercussions.
      </p>
      <a href="#" className='footer-logo ' >webkit</a>
    </div>

      </>
     

  )
}

export default FooterSphere