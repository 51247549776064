import React from 'react'
import NavbarPage from '../components/Navbar'

const Careers = () => {
  return (<>
    <NavbarPage/>
  

    <div>Careers</div>
  </>
  )
}

export default Careers