import {createTypes} from 'reduxsauce';

export default createTypes(
    `UPDATE_LOGGED_IN_STATUS
     UPDATE_USER_ID
    `, 

    {
        prefix:"user/"
    }
)
