import React, { useState } from "react";
import formContext from "./formContext";

const FormState = (props)=>{
    const formStates = {
        "isHovering" : false,
    }

    const [state1, setState1] = useState(formStates);
    
    const updateFormState = () => {
        
        setState1({
            "isHovering": !state1.isHovering,
        });
        console.log(`new state1 value is ${state1.isHovering}`);

       
    }

    return(
        <formContext.Provider value={{state1, updateFormState}}>
            {props.children}
        </formContext.Provider>
    )

}

export default FormState;