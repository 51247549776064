import React from 'react'
import FooterSphere from './FooterSphere';
import './Footer.css'

const Footer = () => {
  return (<><footer className=' mt-0.5 mb-3'>
    <div>
      
    <FooterSphere/>
    </div>


    
  </footer>
  </>
  )
}

export default Footer;