import React, { Component, createContext, useState, debounce } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from "mdbreact";
import { BrowserRouter as Router, NavLink,Link, Route ,Routes} from 'react-router-dom';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './NavbarSecond.css';
import './NavMobview.css';
// import LogoNew from '../images/LogoNew.png ,';
import LogoNew from '../images/LogoNew.png';
// import '../node_modules/mdbreact/docs/css/mdb.min.css';
import 'mdbreact/dist/css/mdb.css';

import { useContext } from "react";
import formContext from "../context/formControls/formContext";
import updateFormState from "../context/formControls/formContext";
import WhatWeDo from "../screens/WhatWeDo";
import WhoWeAre from "../screens/WhoWeAre";
import Careers from "../screens/Careers";
import OverviewPage from "../screens/Overview";
import ServicesPage from "../screens/Services";


class NavbarPage extends Component {
  
  
  
state = {
  isOpen: false,
  colorClass : "Navbarcustom",
  textColorClass:'navTextColor',
  togglerColorClass:"navToggleWhite",
  colorClassVar: false,
  dropPara: true,
  mobScreen: true,
  activeDropdown:"",
  dropMenu:true,
};

 

// static contextType = formContext;



toggleCollapse = () => {
  this.setState({ isOpen: !this.state.isOpen });
  this.setState({ colorClassVar: !this.state.colorClassVar });
}
listenScrollEvent = e => {
  if (window.scrollY > 700) {
    this.setState({colorClass: 'Navbarcustomnew'})
    this.setState({textColorClass:'navTextColornew'})
    this.setState({togglerColorClass:'navToggleDark'})
    this.setState({dropMenu:false})
    
  } else {
    this.setState({colorClass: 'Navbarcustom'})
    this.setState({textColorClass:'navTextColor'})
    this.setState({togglerColorClass:'navToggleWhite'})
    this.setState({dropMenu:true})

  }
  
  
// console.log(this.props)
}

listenResizeEvent = e =>{
  if(window.innerWidth>786){
    this.setState({mobScreen:false})
  }else{
    this.setState({mobScreen:true})
  }
  console.log(window.innerWidth)
}

handleDropParaVisibilty = (e: Boolean) => {
  this.setState({dropPara: e,});
  console.log("dropPara value",e)
}
handleDropdownClick = (dropdownId) =>{
  if(this.state.activeDropdown === dropdownId){
    this.setState({
      activeDropdown: " ",
    })
  }else{
  
    this.setState({
      activeDropdown: dropdownId,
    });
  
  console.log("handleDropdownClick: activeDropdown value is "+ this.state.activeDropdown);
  }

}







componentDidMount() {
  window.addEventListener('scroll', this.listenScrollEvent)
  window.addEventListener('resize',this.listenResizeEvent)
  // window.addEventListener("mouseEvent",this.handleMouseOver),
  // window.addEventListener("mouseEvent",this.handleMouseOut),
  // const updateHover = this.context.updateFormState;
  // console.log(updateHover);
  
// console.log(this.props)
  
}




render() {
  // const [navColor, setNavColor] = useState('white');
  // console.log(this.props);
  

  

  return (
  
    
      <MDBNavbar dark expand="md" className={` ${this.state.colorClass}   `}>
        <MDBNavbarBrand className="Navbarpadding" >
          <Link to={"/"}>
        <img
              className="d-block w-100 navbarlogo"
              src={LogoNew}
              alt="First slide"
            /></Link>
          {/* <strong className="white-text">Navbar</strong> */}
        </MDBNavbarBrand>
        <MDBNavbarToggler className={`${this.state.togglerColorClass} `} onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar className="dropdown-height">
          <MDBNavbarNav right className="navalign">
            
            <MDBNavItem active >
              <MDBDropdown className={`${this.state.activeDropdown ==="dropdown-1" ? " drop-btn-focus":""}`} >
                <div ref={this.props.dropRef} onMouseEnter={this.props.handleDropMouseEnter} onMouseLeave={this.props.handleDropMouseLeave}>
                
                <MDBDropdownToggle nav >
                <Link to={"/whatwedo"}>
                  
                  <div key={"dropdown-1"} className={`d-none d-md-inline  ${this.state.textColorClass} nav-changed-heading `} 
                  onClick = {() => this.handleDropdownClick("dropdown-1")} 
                  >What We Do <i className={`d-l-none d-md-none d-sm-inline d-xs-inline  dropdown-arrow ${this.state.activeDropdown ==="dropdown-1" ? "dropdown-arrow-up":"dropdown-arrow-down"}`}></i></div>
                 
                  </Link>
                </MDBDropdownToggle>
                {(this.state.dropMenu)?
                <MDBDropdownMenu  className={`dropdown-default dropdown-Changes  ${this.state.activeDropdown ==="dropdown-1" ? "drop-option-show drop-btn-focus":""}`}  >

                <div className="dropdown-box">
                  
                    <div className="dropdown-column">
                    <button className={`dropdown-button overview-navigation overview-text ${this.state.dropPara ? "btnFocused":""}`} onClick={()=> this.handleDropParaVisibilty(true)} ><Link className="dropdown-link-text"  to={"/whatwedo"}>Overview</Link></button>
                    <button className={`dropdown-button services-navigation service-text  ${!this.state.dropPara ? "btnFocused":""}`} onClick={()=> this.handleDropParaVisibilty(false)} ><Link className="dropdown-link-text" to={"/whatwedo"}>Services</Link></button>
                    </div>

                    <div className="dropdown-column">
                      <p className={`dropdown-para empowering-text ${this.state.dropPara ? "drop-show" : "drop-hide"}  `}>Hi! This Overview Text Empowering ideas with tech, turning them into thriving business, and serving humanity's needs with innovation.</p>
                      <p className={`dropdown-para empowering-text ${this.state.dropPara ? "drop-hide" : "drop-show"} `} >Hi! This Service Text Empowering ideas with tech, turning them into thriving business, and serving humanity's needs with innovation.</p>
                    </div>

                </div>
                  
                  </MDBDropdownMenu>
                  :""}
                  </div>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem >
              <MDBDropdown className={`${this.state.activeDropdown ==="dropdown-2" ? " drop-btn-focus":""}`} >
              <div ref={this.props.dropRef} onMouseEnter={this.props.handleDropMouseEnter} onMouseLeave={this.props.handleDropMouseLeave}>
                <MDBDropdownToggle nav >
                <Link to={"/whoweare"}>
                  <div key={"dropdown-2"} className={`d-none d-md-inline ${this.state.textColorClass} nav-changed-heading `} 
                  onClick = {() => this.handleDropdownClick("dropdown-2") }
                  >WhoWeAre <i className={`d-l-none d-md-none d-sm-inline d-xs-inline dropdown-arrow ${this.state.activeDropdown ==="dropdown-2" ? "dropdown-arrow-up":"dropdown-arrow-down"}`}></i></div>
                 
                   </Link>
                </MDBDropdownToggle>

                {(this.state.dropMenu)?
                <MDBDropdownMenu className={`dropdown-default dropdown-Changes ${this.state.activeDropdown ==="dropdown-2" ? "drop-option-show":""}`}  >
                <div className="dropdown-box">
                  
                    <div className="dropdown-column">
                    <button className={`dropdown-button overview-navigation overview-text dropdown-link-text ${this.state.dropPara ? "btnFocused":""}`} onClick={ ()=> this.handleDropParaVisibilty(true)} ><Link className="overview-text dropdown-link-text" to={"/whoweare"}>Overview</Link></button>
                    <button className={`dropdown-button services-navigation  service-text dropdown-link-text ${!this.state.dropPara ? "btnFocused":""}`} onClick={ ()=> this.handleDropParaVisibilty(false)} ><Link className="service-text dropdown-link-text" to={"/whoweare"}>Services</Link></button>
                    </div>

                    <div className="dropdown-column">
                      <p className={`dropdown-para empowering-text ${this.state.dropPara ? "drop-show" : "drop-hide"}  `}>Hi! This Overview Text Empowering ideas with tech, turning them into thriving business, and serving humanity's needs with innovation.</p>
                      <p className={`dropdown-para empowering-text ${this.state.dropPara ? "drop-hide" : "drop-show"} `} >Hi! This Service Text Empowering ideas with tech, turning them into thriving business, and serving humanity's needs with innovation.</p>
                    </div>

                </div>
                  
                </MDBDropdownMenu>
                :""}
                </div>
              </MDBDropdown>
            </MDBNavItem>
            {/* <MDBNavItem>
              <MDBNavLink to="#!">About</MDBNavLink>
            </MDBNavItem> */}
            <MDBNavItem>
              <MDBDropdown  className={`${this.state.activeDropdown ==="dropdown-3" ? " drop-btn-focus":""}`}>
              <div ref={this.props.dropRef} onMouseEnter={this.props.handleDropMouseEnter} onMouseLeave={this.props.handleDropMouseLeave} >
                <MDBDropdownToggle nav >
                <Link to={"/careers"}>
                  <div key={"dropdown-3"} className={`d-none d-md-inline ${this.state.textColorClass} nav-changed-heading `} 
                  onClick = {()=> this.handleDropdownClick("dropdown-3") }
                  >Careers <i className={`d-l-none d-m-none d-sm-inline d-xs-inline  dropdown-arrow ${this.state.activeDropdown ==="dropdown-3" ? "dropdown-arrow-up":"dropdown-arrow-down"}`}></i></div>
                 
                    </Link>
                </MDBDropdownToggle>

                {(this.state.dropMenu)?
                <MDBDropdownMenu  className={`dropdown-default dropdown-Changes ${this.state.activeDropdown ==="dropdown-3" ? "drop-option-show":""} ${this.state.textColorClass} `}>
                <div className="dropdown-box ">
                  
                  <div className="dropdown-column">
                  <button className={`dropdown-button overview-navigation overview-text ${this.state.dropPara ? "btnFocused":""}`} onClick={()=> this.handleDropParaVisibilty(true)} ><Link className="dropdown-link-text" to={"/careers"}>Overview</Link></button>
                  <button className={`dropdown-button services-navigation service-text  ${!this.state.dropPara ? "btnFocused":""}`} onClick={()=> this.handleDropParaVisibilty(false)} ><Link className="dropdown-link-text" to={"/careers"}>Services</Link></button>
                  </div>

                  <div className="dropdown-column">
                    <p className={`dropdown-para empowering-text ${this.state.dropPara ? "drop-show" : "drop-hide"}  `}>Hi! This Overview Text Empowering ideas with tech, turning them into thriving business, and serving humanity's needs with innovation.</p>
                    <p className={`dropdown-para empowering-text ${this.state.dropPara ? "drop-hide" : "drop-show"} `} >Hi! This Service Text Empowering ideas with tech, turning them into thriving business, and serving humanity's needs with innovation.</p>
                  </div>

              </div>
                  </MDBDropdownMenu>
                  :""}
                  </div>
              </MDBDropdown>
            </MDBNavItem>
            {/* <MDBNavItem>
              <MDBNavLink  className={` ${this.state.textColorClass} nav-changed-heading `}  to="#!">About</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink className={` ${this.state.textColorClass} nav-changed-heading `} to="#!">Portfolio</MDBNavLink>
            </MDBNavItem> */}
            <MDBNavItem id="nav-contactus">
              <Link className={` ${this.state.textColorClass} nav-changed-heading `}  to={"/contactpage"}>Contact Us</Link>
            </MDBNavItem>
            
          </MDBNavbarNav>
          {/* <MDBNavbarNav right>
            <MDBNavItem>
              <MDBNavLink className="waves-effect waves-light" to="#!">
                <MDBIcon fab icon="twitter" />
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink className="waves-effect waves-light" to="#!">
                <MDBIcon fab icon="google-plus-g" />
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon="user" />
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default">
                  <MDBDropdownItem href="#!">Action</MDBDropdownItem>
                  <MDBDropdownItem href="#!">Another Action</MDBDropdownItem>
                  <MDBDropdownItem href="#!">Something else here</MDBDropdownItem>
                  <MDBDropdownItem href="#!">Something else here</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav> */}
        </MDBCollapse>
      </MDBNavbar>
     
   
    );
  }
}

export default NavbarPage;