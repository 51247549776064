import react from 'react';
import axios from 'axios';
const BASE_API_URL = 'http://localhost:3000';
const api= axios.create({
baseURL: BASE_API_URL,
timeout:1000,
headers:{},
withCredentials:true
});

export default api;