import React from 'react'
import NavbarPage from '../components/Navbar'

const OverviewPage = () => {
  return (<>
  
  <NavbarPage/>
  
    <div>Overview</div>
  </>
  )
}

export default OverviewPage;