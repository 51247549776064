import React, { useContext, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import './ContactForm.css';

import {animated,useSpring} from 'react-spring/'
import rocket_img from '../../images/rocket_img.jpg';
import { useState } from 'react';
import '../Rocket.css'
import formContext from "../../context/formControls/formContext";


const ContactForm = (props) => {
// console.log(props)
// const hover = ((!props.dropdownHover) && (!props.mouseHover) )
//   console.log("hover value is : "+ hover+ " and bgHover is "+props.bgHover)

  const [classes, setClasses] = useState('');

  // const hoverContext = useContext(formContext);
  // console.log(`the value of hoverContext is :${hoverContext.state1.isHovering}`);

  // useEffect(() => {
  //   hoverContext.updateFormState();
  // }, [])
  


  const style = {
    height:'50px',
   }

   const initialPoint = { x:"-15vw", y:'12vh'}
   const finalPoint = {x:'94vw', y:'96vh'}

    const [spring, api] = useSpring(()=>({
     from:{ x:"-15vw", y:'12vh'},
    //  to: classes === 'active'? finalPoint:initialPoint,
    //  to: initialPoint,
     loop: true,     
    },[]))
   
    

    const handleBtnClick = (event) => {
      console.log("hi this is HandleBtnClick line: 35", event)
      setClasses('active');
      setTimeout(() => {
        setClasses('')
      }, 3000);
      return 
     
      
    }


return (<>
<div className="rocket-body" style={{zIndex:-2}}>
      <animated.div className={`rocket-container ${classes}`} style={spring} >
          <div className='rocket' >
              <img src={rocket_img} alt="rocket" style={style}/>
          </div>
      </animated.div>
</div>
{/* {(hoverContext.state1.isHovering) && ( */}
{/* {( hover ) && ( */}
<MDBContainer  sm = "12" className="contactcontainer" >
  <MDBRow>
    <MDBCol md="6" sm = "12" className="contactform">
    
      <form >
        <p className="h5 text-center mb-4 formheading">Happy To Hear You </p>
        <div className="grey-text">
          <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
            success="right" />
          <MDBInput label="Your email" icon="envelope"  group type="email" validate error="wrong"
            success="right" />
          <MDBInput label="Your's Contact Number" icon="tag" group type="text" validate error="wrong" success="right" />
          <MDBInput type="textarea" rows="2" label="What you are looking for" icon="pencil-alt" />
        </div>
        <div className="text-center">
          <MDBBtn outline onClick={(event)=> handleBtnClick(event.target)}  >
            Send
            <MDBIcon far icon="paper-plane" className="ml-1" />
          </MDBBtn>
        </div>
      </form>
    </MDBCol>
  </MDBRow>
</MDBContainer>
{/* )} */}
</>
);
};

export default ContactForm;